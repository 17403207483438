(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': { 
      init: function() {
        // <!--- PUT STUFF IN THIS FUNCTION !!! JavaScript to be fired on all pages
  

        /* ----------------------------------------------------
          Notifications banner placement */
        function notifications_banner() {
          if($("#notifications-banner").length > 0) {
            if($("#notifications-banner").hasClass('fixed-bottom')) {
              $('body').css('padding-bottom', $("#notifications-banner").outerHeight());
            }
            else {
              $('body').css('padding-top', $("#notifications-banner").outerHeight());
            }
          }
        }

        function menu_position() {
          var sidebar = $(".home .layout-hero .sidebar");

          if($(window).width() <= 992) {
            $('.offcanvas-collapse').css('top', $('#header').height()); 
          }
          else {
            $('.offcanvas-collapse').css('top', 0); 
          }

          $('#navbarLayout .column').css('left', 'auto');
          $('.offcanvas-collapse').css('width', '100%');

          if(sidebar.length>0 && $(window).width()>992) {
            var sidebar_start = sidebar.css('left');
            var offset = sidebar.offset();

            var actualInnerWidth = $("body").prop("clientWidth"); // El. 

            $('.offcanvas-collapse').css('width', $(window).width() - ($(window).width() - offset.left)); 

            var sidebar_width = $(window).width() - $('.offcanvas-collapse').width();
            sidebar_width += 17;
            
            $(sidebar).css('width', sidebar_width); 

            var logo_offset = $(".navbar-brand").offset();
            $('#navbarLayout .column').css('left', logo_offset.left);
         //   $('#navbarLayout .')
          }
        }

        $(window).resize(function() { 
          notifications_banner(); 
          menu_position();
        }); // onresize

        $(window).load(function(){ 
          notifications_banner(); 
          menu_position();
        }); // onload     
        /* End notifications banner 
           ----------------------------------------------------*/
        
        $(window).on("load", function (e) {
          $('a[href^="#"]:not([role="tab"]').on('click',function (e) {
              e.preventDefault();

              var target = this.hash;
              var $target = $(target);

              if($target.length>0) {

                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - 120
                }, 900, 'swing', function () {
                    window.location.hash = target;
                });
              }
          });

        }); 

        // Menu
        var $navbar_toggler = $('#navbar-toggler');

        if($('body').hasClass('logged-in')) {
          $('.offcanvas-collapse').css('top', $('#header').height() + $('#wpadminbar').height());
        }

        $('[data-toggle="offcanvas"]').on('click tap press', function (e) {
          $('.offcanvas-collapse').toggleClass('open');
          $('body').toggleClass('navbar-open modal-open');
          $("#header").toggleClass('navbar-open');
          $navbar_toggler.toggleClass('collapsed');


        });


        // ScrollReveal
        // window.sr = new ScrollReveal();
        // sr.reveal('.reveal');
        // // Reveal in a ripple efffect
        // sr.reveal('.my-class', { duration: 800 }, 70);


        $(".project-teaser").css('cursor', 'pointer').on('click tap press', function(e){
          if(e.target.nodeName === 'I') {
            return false;
          }
          var $href = $(this).find("a").attr("href");
          e.preventDefault();
          if($href.length>0) {
            window.location = $href;
          }
          return false;
        });

        $(".post-teaser").matchHeight();


        $('.layout-projects-slider .slick-carousel').slick({
          centerMode: false,//true,
          infinite: true,
          variableWidth: false,//rue,
        //  lazyLoad: 'ondemand',
          slidesToShow: 3, 
          slidesToScroll: 1,
          arrows: true,
          prevArrow: $('.layout-project-category .slick-prev'),
          nextArrow: $('.layout-project-category .slick-next'),
          responsive: [
            {
              breakpoint: 769,
              settings: {
                //arrows: false,
                centerMode: false,
                variableWidth: false, 
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 576,
              settings: {
                //arrows: false,
                centerMode: false,
                infinite: true,
                variableWidth: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        /*
         * Replace all SVG images with inline SVG
         */
        $('img.svg').each(function(){
            var $img     = jQuery(this);
            var imgID    = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL   = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // $('#myCarousel').on('slide.bs.carousel', function () {
        //   // do something…
        // });
        $('#services-carousel').on('slide.bs.carousel', function onSlide (ev) {
          var id = ev.relatedTarget.id;
          console.log(ev);
          console.log(id);
          $('.homepage-carousel-image').css('opacity', 0);
          $('.carousel-image-' + ev.to).css('opacity', 1);

          // switch (id) {
          //   case "1":
          //     // do something the id is 1
          //     break;
          //   case "2":
          //     // do something the id is 2
          //     break;
          //   case "3":
          //     // do something the id is 3
          //     break;
          //   default:
          //     //the id is none of the above
          // }
        });

        function header_switch() {

          if($(window).width() < 992) {
            //return;
          }

          var height = $(window).scrollTop();
          //console.log(height);
          $("#header").addClass('scrolled-top');
          if(height > 200) {
            $("#header").removeClass('scrolled-top');
          }
        }

        $(window).load(function(){ 
          header_switch();
        });

        $(window).scroll(function() { 
          header_switch();
        });

      }
    },

    // Contact
    'template_contact': {
      init: function() {

        var infowindow;

        console.log('contact');


        function add_marker( $marker, map ) {

          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          
          // var icon
          var image = {
            url: stylesheet_directory_uri + '/dist/images/icon-PIN-SOLID.png', // image is 512 x 512
            scaledSize : new google.maps.Size(30, 47)
          };

          // create marker
          var marker = new google.maps.Marker({
            position  : latlng,
            map       : map,
            icon      : image
          });

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() ) {
            // create info window
            infowindow = new google.maps.InfoWindow({
              content   : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
              // Close others
              if (infowindow) {
                infowindow.close();
              }
              
              infowindow.open( map, marker );
              
            });
          }

        }

        function center_map( map ) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

          });

          // only 1 marker?
          if( map.markers.length === 1 )
          {
            // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
          }
          else
          {
            // fit to bounds
            map.fitBounds( bounds );
          }

        }

        function new_map( $el ) {
          
          // var
          var $markers = $el.find('.marker');

          var map_styles = [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                // "stylers": [
                //     {
                //         "color": "#383838"
                //     }
                // ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#e0d8ca"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
              },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#9bb8c4"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }];
        
          
          // vars
          var args = {
            zoom      : 15,
            styles    : map_styles,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP
          };
          
          
          // create map           
          var map = new google.maps.Map( $el[0], args);
          
          
          // add a markers reference
          map.markers = [];
          
          
          // add markers
          $markers.each(function(){
            add_marker( $(this), map );
          });

          google.maps.event.addListener(map, "click", function(event) {
            infowindow.close();
          });
                      
          
          // center map
          center_map( map );
          
          // return
          return map;
          
        }

        var map = null;
        $('.acf-map').each(function(){
          // create map
          map = new_map( $(this) );
        });

      }
    },


    'single_project': {
      init: function() {

        console.log('Project');

        $('.layout-project-hero .slick-carousel').slick({
          centerMode: true, 
          infinite: true,
          variableWidth: true,
          lazyLoad: 'ondemand',
          slidesToShow: 1, 
          slidesToScroll: 1,
          arrows: true,
          prevArrow: $('.layout-project-hero .slick-prev'),
          nextArrow: $('.layout-project-hero .slick-next'),
          responsive: [
            {
              breakpoint: 576,
              settings: {
                //arrows: false,
                centerMode: false,
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }
    }



  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
